import {Container,Row, Col,Button} from 'react-bootstrap'
import React from "react"
import Layout from "../components/layouts/layout"
import Pageart from "../components/pageart/pageart"
import SEO from "../components/seo"
import {Link} from "gatsby"

const SuppPage = () => (

  <Layout>

    <div className="supplements">

      <SEO title="Supplements Don’t Reduce CV Risk" 
           description="Learn why supplements aren’t FDA-approved to treat, cure, or prevent disease, nor are they proven effective in doing so. Millions place hope in fish oil supplements for heart protection beyond what cholesterol, diabetes, and blood pressure medications provide."
      />

      <Pageart content="Fish Oil Supplements:" content2="ARE THEY RIGHT FOR YOU?"></Pageart>

      <Container fluid className="page-container">
        <Row>

          <Col className="content-wrapper">


            <p>Many consumers take fish oil supplements to balance out their diet. But if you’re facing persistent CV risk (P-CVR), then it’s time to get real about what these fish oil supplements actually mean for your heart.</p>

            <p>Many patients at risk for a heart attack or stroke are looking for protection beyond what their current cholesterol, diabetes, and blood pressure medications provide.</p>

            <p>That's led millions of patients to place hope in fish oil supplements, which are not intended to be used for cardiovascular (CV) patients.<sup>36</sup></p>

            <p style={{paddingBottom:"0px"}} className="callout border-bottom-0 mb-0"><span class="fs-40 lh-1i"><strong>But here’s the catch: Fish oil supplements aren’t FDA-approved</strong>,</span> intended to treat, cure, or prevent diseases—nor are they proven effective in <span className="text-nowrap">doing so.</span><sup>12</sup></p>


            <Row className="blocked mt-0 border-top-0">
              <Col style={{paddingBottom: "15px"}} className="my-auto pl-0">

                <h3 className="fs-45">

                  After many failed <span className="break"><br/></span>
                  attempts, <strong>fish oil <span className="break"><br/></span>
                  supplements have <span className="break"><br/></span>
                  not successfully <span className="break"><br/></span>
                  proven a <span className="break"><br/></span>
                  CV benefit in <span className="text-nowrap">clinical studies.</span></strong><sup>37,38*</sup></h3>

              </Col>

              <Col style={{padding: "35px 25px 0px 25px"}}   className="pl-5 pb-4">

                <h4>There are multiple reasons for this:</h4>

                <ol className="list list-numbered">
                  <li>Multiple types of omega-3s, each with varying molecular structures and different efficacy, safety, and clinical effects, can behave differently based on how they are manufactured, handled, and administered.<sup>39,40</sup></li>
                  <li>Typical fish oil supplements contain <strong>only 30% of the omega-3 fatty acids EPA and DHA</strong>, with the majority of product consisting of non-omega-3 ingredients, often including saturated fats.<sup>40,41</sup></li>
                  <li>Certain ingredients in fish oil supplements such as docosahexaenoic acid (DHA) may raise bad cholesterol <span className="text-nowrap">LDL-C.<sup>42</sup></span></li>
                  <li>The science of omega-3s in cardiac health is complex. While there may be useful purposes for fish oil supplements, it's important to note that they are considered food, and are not intended to treat diseases.<sup>12,40</sup></li>
              </ol>

              </Col>

            </Row>


            <h4>If you're at risk for a heart attack <span className="text-nowrap">or stroke</span></h4>

            <p>don't self-medicate with supplements.  Work with your doctor on a proven plan to reduce your <span className="text-nowrap">risk today.</span></p>

            <p className="text-center mt-5">
              <Link class="btn btn-primary" to="/heart-healthy-habits">SEE HEART-HEALTHY HABITS</Link>
            </p>

            <p className="footnote">DHA=docosahexaenoic acid; EPA=eicosapentaenoic acid.</p>

            <p className="fs-14"><span className="hang">*</span>This meta-analysis demonstrated that omega-3 fatty acids showed no significant association with fatal or nonfatal coronary heart disease or reductions in any major cardiovascular events. It provides no support for current recommendations for the use of such supplements in people with a history of coronary heart disease.<sup>37</sup></p>

          </Col>
        </Row>
      </Container>

    </div>

  </Layout>

)

export default SuppPage
